import React from "react";
import Layout from "../components/layout";
import "../templates/article.css";

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <div className="titlebox">
      <h1>404: Not found</h1>
    </div>
    <span className="subtitle">
      <p>
        <i>"Would you tell me, please, which way I ought to go from here?"</i>
      </p>
      <p>
        <i>"That depends a good deal on where you want to get to."</i>
      </p>
      There's no place like <a href="/">home</a>.
    </span>
  </Layout>
);

export default NotFoundPage;
